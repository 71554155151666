import React, { Fragment, useState, useEffect } from "react"
import { createGlobalStyle } from 'styled-components'
import styled from "styled-components"
import backgroundGroundImg from '../images/features-bkgd.png'
import iconArrowRightOrange from '../images/icon-arrow-right-orange.svg'
import talkIcon from '../images/product_talk.png'
import { graphql } from 'gatsby'
import formHtml from '../form.html'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'
import shareImage from '../images/share.png'

import Logo from '../components/Logo'
import Footer from '../components/Footer'

const headingFont = `'Nunito', sans-serif`
const copyFont = `'Nunito Sans' , sans-serif`
const smallScreen = `min-width: 576px`
const mediumScreen = `min-width: 768px`
const largeScreen = `min-width: 992px`
const xlargeScreen = `min-width: 1200px`

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: ${copyFont};
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 10px;
  }

  a {
    color: #8368DB;
    word-break: break-all;
  }

  li {
    margin-bottom: 1rem;
  }

  h1, h2, h3, h4 {
    font-family: ${headingFont};
    margin-top: 0;
    margin-bottom: 1rem;
    color: #8368DB;
  }

  h1 {
    font-size: 2.625rem;

    @media(${mediumScreen}) {
      font-size: 3.25rem;
    }
  }

  h2 {
    font-size: 2.185rem;
    margin-top: 1rem;

    @media(${mediumScreen}) {
      font-size: 2.625rem;
    }
  }

  h3 {
    font-size: 1.6875rem;

    @media(${mediumScreen}) {
      font-size: 2rem;
    }
  }
`

const Main = styled.main`
  border-radius: 10px;
  background: rgb(246,243,255);
  background: linear-gradient(0deg, rgba(246,243,255,1) 0%, rgba(239,235,250,1) 100%);
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding-bottom: 150px;
`
const Content = styled.div`
  max-width: 768px;
  padding: 1rem;
  margin: 0 auto;
 `

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 1rem;

  @media(${mediumScreen}) {
    padding: 2rem;
  }
 `

const ErrorPage = ({ data }) => {
  return (
    <Fragment>
      <GlobalStyle />
      <Helmet title="404 | Happy Holidays from SeeBotRun!">
        <link rel="icon" href={favicon} />
        <meta property="og:image" content={shareImage} />
      </Helmet>
      <Main>
        <Content>
          <Logo />
          <Wrapper>
            <h1>404</h1>
          </Wrapper>
        </Content>
        <Footer />
      </Main>
    </Fragment>
  )
}

export default ErrorPage
